@font-face {
  font-family: "NeueBit";
  src: url("assets/fonts/NeueBit-Bold.woff2") format("woff2"),
    url("assets/fonts/NeueBit-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
@font-face {
    font-family: "Sofia Pro";
    src: url("assets/fonts/SofiaPro-Italic.woff2") format("woff2"),
      url("assets/fonts/SofiaPro-Italic.woff") format("woff");
    font-weight: normal;
    font-style: italic;
    font-display: swap;
  }
  
  @font-face {
    font-family: "Sofia Pro";
    src: url("assets/fonts/SofiaPro-Bold.woff2") format("woff2"),
      url("assets/fonts/SofiaPro-Bold.woff") format("woff");
    font-weight: bold;
    font-style: normal;
    font-display: swap;
  }
  
  @font-face {
    font-family: "Sofia Pro";
    src: url("assets/fonts/SofiaPro-Black.woff2") format("woff2"),
      url("assets/fonts/SofiaPro-Black.woff") format("woff");
    font-weight: 900;
    font-style: normal;
    font-display: swap;
  }
  
  @font-face {
    font-family: "Sofia Pro Extra";
    src: url("assets/fonts/SofiaPro-ExtraLight.woff2") format("woff2"),
      url("assets/fonts/SofiaPro-ExtraLight.woff") format("woff");
    font-weight: 200;
    font-style: normal;
    font-display: swap;
  }
  
  @font-face {
    font-family: "Sofia Pro";
    src: url("assets/fonts/SofiaPro-Light.woff2") format("woff2"),
      url("assets/fonts/SofiaPro-Light.woff") format("woff");
    font-weight: 300;
    font-style: normal;
    font-display: swap;
  }
  
  @font-face {
    font-family: "Sofia Pro";
    src: url("assets/fonts/SofiaPro-BlackItalic.woff2") format("woff2"),
      url("assets/fonts/SofiaPro-BlackItalic.woff") format("woff");
    font-weight: 900;
    font-style: italic;
    font-display: swap;
  }
  
  @font-face {
    font-family: "Sofia Pro Extra";
    src: url("assets/fonts/SofiaProExtraLight-Italic.woff2") format("woff2"),
      url("assets/fonts/SofiaProExtraLight-Italic.woff") format("woff");
    font-weight: 200;
    font-style: italic;
    font-display: swap;
  }
  
  @font-face {
    font-family: "Sofia Pro";
    src: url("assets/fonts/SofiaPro-BoldItalic.woff2") format("woff2"),
      url("assets/fonts/SofiaPro-BoldItalic.woff") format("woff");
    font-weight: bold;
    font-style: italic;
    font-display: swap;
  }
  
  @font-face {
    font-family: "Sofia Pro";
    src: url("assets/fonts/SofiaPro-MediumItalic.woff2") format("woff2"),
      url("assets/fonts/SofiaPro-MediumItalic.woff") format("woff");
    font-weight: 500;
    font-style: italic;
    font-display: swap;
  }
  
  @font-face {
    font-family: "Sofia Pro";
    src: url("assets/fonts/SofiaPro-LightItalic.woff2") format("woff2"),
      url("assets/fonts/SofiaPro-LightItalic.woff") format("woff");
    font-weight: 300;
    font-style: italic;
    font-display: swap;
  }
  